<template>
  <div>

    <!--header-->
    <div class="header">
        <h3>云上课堂</h3>
        <el-input class="search" placeholder="请输入关键词" v-model="keys" @keyup.native="handelKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>

         <div @click="toAdmin" class="admin-p">
            管理后台
        </div>
    </div>

   

    <!--content-->
    <div 
        class="content" 
        v-loading="loading" 
        element-loading-background="rgba(0, 0, 0, 0.2)"
        element-loading-text="拼命加载中">
        <template v-if="total">
            <div class="list">
                <div class="item" v-for="item of list" :key="item.id" @click="handelItem(item)">
                    <div class="imgBox">

                        <el-image :src="item.ccCoverPath" fit="cover" style="width:100%;height:100%;">
                            <div slot="error" class="image-slot">
                            </div>
                        </el-image>
                        
                      
                    </div>
                    <div class="textBox">
                        <h4>{{item.courseName}}</h4>
                        <div class="time">报名时间：{{item.signUpTime}}</div>
                        <div class="section">
                            <span class="name">（含{{item.resourceNum + item.liveNum}}节课）</span>
                            <span style="color:#00A2FF">{{item.chargeNum > 0 ? '￥'+item.chargeNum+'元' : '免费' }}</span>
                        </div>
                        <div class="ownership">
                            <span class="name">{{item.schoolName}}</span>
                            <!-- <span class="btn">报名</span> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="pagination">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </template>
       
        <!--noData-->
        <div class="noData" v-else>
            <div class="noData-text">暂无课程</div> 
        </div>
       
    </div> 

  </div>
</template>


<script>

//防抖节流
function debounce(func, wait=1000){ 
    let timeout;
    return function(event){
        clearTimeout(timeout)
        timeout = setTimeout(()=>{
            func.call(this, event)
        },wait)
    }
}
import {adminPageLive, teacherPageLive, teacherPageCourse} from '@/api/classRoomApi'
import {eduUrl} from '@/config/config'
import cookie from '@/util/cookie'
import {wwwUrl} from '@/config/config'
export default {
    name: 'list',
    data(){
        return {
            keys:'',
            currentPage:1,
            pageSize:10,
            total:0,
            list:[],
            loading:false
        }
    },
    computed:{
        userInfo(){
          return this.$store.state.user.countyUserInfo
        }
    },
    methods: {
        //每页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList(1)
        },

        //当前页
        handleCurrentChange(val) {
           
            this.getList(val)
        },

        //搜索
        handelKey:debounce(function(e){
            this.getList(1)
        }),

        //详情
        handelItem(item){
            this.$router.push({
                path:'/classroom/info',
                query:{
                    id: item.id
                }
            })
        },

        //获取数据
        async getList(pageNum){
             this.currentPage = pageNum
            this.loading = true
            const res = await teacherPageCourse({
                eduId:this.userInfo.organizationIdStr,
                // eduId:'39fef0b5b28fd23b0b0d16dad02bef5c',
                limit:this.pageSize,
                page:this.currentPage,
                courseName:this.keys
            })
            
            if (res.status !== 200){
                this.list = []
                this.total = 0
                this.loading = false
                return this.$message.error(res.msg)
            } 

            let total = res.data.total
            let list = res.data.records

            if (total > 0){
                for(let item of list){
                    item.chargeNum = Number(item.chargeNum).toFixed(2)
                }
            }

            this.list = list
            this.total = total
            this.loading = false
        },

        toAdmin(){
            if(!cookie.get('userInfo')){
                location.href = wwwUrl + '#/login'
                return
            }
            
            window.open(eduUrl + '/console/index.html#/specialTeacher/list')
        }
    },
    activated(){
        
    },
    mounted() {
        
        this.getList(1)
    }
}
</script>


<style scoped lang="scss">
.header{
    height:0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding:0 0.58rem;
    h3{
        color:#fff;
        font-size:0.26rem;
    }
    .search{
        width:3.16rem;
        height:0.38rem;
        border:0.01rem solid #00A2FF;
        border-radius: 0.08rem;
        overflow: hidden;
    }
}
.content{
    min-height:calc(100vh - 1.5rem);
    position: relative;
    .noData{
        position: absolute; left: 50%; top: 50%; transform: translateY(-50%) translateX(-50%);
        height:2.4rem; width: 2.96rem; 
        background:url(../../assets/img/noData.png) center no-repeat;
    }
    .noData-text{
        text-align: center; color: #B1CEF4;
        width: 100%; position: absolute;bottom: 0;
    }
    .list{
        height: auto;
        min-height:7.6rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding:0 0.4rem;
        .item{
            cursor: pointer;
            width:3.38rem;
            height:3.5rem;
            background-color:#fff;
            border-radius: 0px 0px 8px 8px;
            margin:0 0.1rem 0.3rem;
            .imgBox{
                height:1.96rem;
                overflow: hidden;
                background:#f4f8fb;
                display: flex;
                justify-content: center;
                align-content: center;
                img{
                    object-fit:cover;
                    transition: transform 2000ms;
                }
                img:hover{
                    transform: scale(1.2);
                }
            }
            .textBox{
                box-sizing: border-box;
                padding:0 0.15rem;
                h4{
                    font-size:0.2rem;
                    color:#000;
                    margin-top:0.15rem;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .time,.section,.ownership{
                    font-size:0.16rem;
                    color:#666;
                    margin-top:0.10rem;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .section,.ownership{
                    display: flex;
                    justify-content: space-between;
                    .name{
                        flex:1;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        margin-right:0.15rem;
                    }
                    .btn{
                        display: inline-block;
                        width: 0.54rem;
                        height: 0.26rem;
                        line-height:0.26rem;
                        background: #FF6600;
                        border-radius: 0.03rem;
                        font-size:0.14rem;
                        color:#fff;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                    }
                }
            }
        }
    }
    .pagination{
        padding:0 0.8rem;
        text-align: center;
    }
}
</style>


<style>
.image-slot{
    width:3.38rem;
    height:1.96rem;
    background: url(../../assets/img/icon.png) center no-repeat;
    background-size:1rem;
}
.search.el-input-group--append .el-input__inner{
    height:0.38rem;
    background-color:#041340;
    border:none;
    color:#00A2FF;
    -webkit-text-fill-color: #00A2FF;
    caret-color: #00A2FF;
}
.search.el-input-group--append .el-input-group__append{
    background-color:#041340;
    border:none;
    color:#00A2FF;
}
.search.el-input-group--append .el-input-group__append .el-icon-search:before{
    font-size:0.18rem;
}
.pagination .el-pagination .btn-prev,
.pagination .el-pagination .btn-next,
.pagination .el-pagination .el-pager li,
.pagination .el-pagination .el-pagination__jump .el-input .el-input__inner{
    width: 0.36rem;
    height: 0.36rem;
    line-height:0.36rem;
    background: #011343;
    color:#1082D6;
    border: 1px solid #1082D6;
    font-weight: initial;
}
.pagination .el-pagination .el-pagination__jump,
.pagination .el-pagination.is-background .btn-next:disabled{
    color:#1082D6;
}
.pagination .el-pagination .el-pager li.active{
    background-color: #1082D6 !important;
}
.admin-p{
    color: #fff; cursor: pointer;
}
.item-img{
    width: 100%; height: 100%; object-fit: cover;
}
</style>

