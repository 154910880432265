import axios from './axios'
import qs from 'qs'
import {apiUrl, cloudUrl} from '@/config/config'

// 区县管理员页
export const adminPageLive = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/edu_live/district_center_live/adminPage',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

// 教师页
export const teacherPageLive = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/edu_live/district_center_live/teacherPage',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

// 区县管理员/教师页列表
export const teacherPageCourse = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/edu_course/district_center_course/adminPage/online',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

// 查询课程详情
export const getCourseDetail = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/course/inquireCloudCourseDetail',
            method: 'post',
            data: qs.stringify(data)
        }).then(res => {
            resolve(res)
        })
    })
}

// 根据课程id查询直播视频（在课程明细页面中）
export const getLiveList = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/live/lives/toCourse',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}

// 查询课程对应的视频资源
export const getVideoList = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/cloud/resource/selectResVideoByCourseToStu',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}


// 获取进入直播间地址
export const getLiveUrl = (data) => {
    return new Promise((resolve,reject) => {
        axios({
            // url: 'http://192.168.66.42:9995' + '/cloud/room/getLiveUrl',
            url: cloudUrl + '/cloud/room/getLiveUrl',
            method: 'post',
            headers: {
                'X-Role-Code': 'CloudClassAdmin'
            },
            data: qs.stringify(data)
        }).then(res => {
            resolve(res)
        })
    })
}